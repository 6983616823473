import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import Homepage from "./components/homepage";
import Navbar from "./components/navbar";
import Footer from "./components/footer";
import Errorpage from "./Pages/errorpage";
import PrivacyPolicy from "./Pages/privacyPolicy";
import CookiePolicy from "./Pages/cookiePolicy";
import Terms from "./Pages/termsService";
import Support from "./components/footer/support";
import Refund from "./components/footer/refund";
import { IndustriesData } from "./data/Industries";
import IndustriesMain from "./Pages/Solutions";
import Industries from "./Pages/Industries/industries";
import CareerPage from "../src/Pages/CareerPage";
import About from "./Pages/About";
import Contact from "./Pages/Contact";
import { Navigate } from "react-router-dom";
import IndustriesRouting from "./components/IndustriesRouting";
import Technology from "./Pages/Technology/Technology";
import AOT from "./Pages/AOT/Analytics_of_things.jsx";
import Indus_Tech from "./components/Indus_Tech";

function App() {
  const [selectedPage, setSelectedPage] = useState("home");
  const [isTopOfPage, setIsTopOfPage] = useState(true);
  const [openChatBot, setOpenChatBot] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY === 0) setIsTopOfPage(true);
      if (window.scrollY !== 0) setIsTopOfPage(false);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div
      className={`h-screen bg-gradient-to-b from-blue to-white ${
        openChatBot ? "overflow-auto" : "overflow-visible"
      } `}
    >
      <Navbar
        selectedPage={selectedPage}
        setSelectedPage={setSelectedPage}
        isTopOfPage={isTopOfPage}
        openChatBot={openChatBot}
        setOpenChatBot={setOpenChatBot}
      ></Navbar>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/cookie-policy" element={<CookiePolicy />} />
        <Route path="/terms-and-conditions" element={<Terms />} />
        <Route path="/career" element={<CareerPage />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/terms-and-conditions" element={<Terms />} />
        <Route path="/solutions" element={<IndustriesMain />} />
        <Route path="/analytics-of-things" element={<AOT />} />
        <Route
          path="/solutions/:title"
          element={<Indus_Tech />}
        />
        {/* <Route
          path="/solutions/:title"
          element={<Industries IndustriesData={IndustriesData} />}
        /> */}

        <Route
          path="/factacy/privacy_policy"
          element={<Navigate to={"/privacy-policy"} />}
        />
        <Route
          path="/factacy/terms_of_service"
          element={<Navigate to={"/terms-and-conditions"} />}
        />
        <Route
          path="/factacy/cookie_policy"
          element={<Navigate to={"/cookie-policy"} />}
        />
        <Route path="/industry" element={<Navigate to={"/solutions"} />} />
        <Route path="/technology" element={<Technology />} />
        <Route path="*" element={<Errorpage />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;

{
  /* <Route path="/support" element={<Support />} />
<Route path="/refund" element={<Refund />} /> */
}
{
  /* <Route
  path="/industry/:title"
  element={<IndustriesRouting />}
/> */
}
{
  /* <Route
  path="/industry/:title"
  element={<Navigate to={"/solutions/"} />}
/> */
}
