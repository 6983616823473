import React from 'react'

const SpeechRecognition = () => {
    return (
        <svg width="800px" height="800px" viewBox="0 0 73 73" version="1.1" xmlns="http://www.w3.org/2000/svg" className="h-12 w-12">
            <g id="the-modern-web/speech-recognition" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="container" transform="translate(2.000000, 2.000000)" fill="#FFFFFF" fill-rule="nonzero" stroke="#292832" stroke-width="2">
                    <rect id="mask" x="-1" y="-1" width="71" height="71" rx="14">

                    </rect>
                </g>
                <g id="speech" transform="translate(14.000000, 14.000000)" fill-rule="nonzero">
                    <polygon id="Shape" fill="#ACABB1" points="21.4666667 28.3666667 24.5333333 28.3666667 24.5333333 39.8666667 21.4666667 39.8666667">

                    </polygon>
                    <circle id="Oval" fill="#FF5023" cx="43.7" cy="10.7333333" r="1.53333333">

                    </circle>
                    <circle id="Oval" fill="#FF5023" cx="41.4" cy="3.06666667" r="1.53333333">

                    </circle>
                    <circle id="Oval" fill="#FF5023" cx="41.4" cy="18.4" r="1.53333333">

                    </circle>
                    <path d="M24.5333333,34.4003333 L24.5333333,37.49 C23.5136667,37.5922222 22.4863333,37.5922222 21.4666667,37.49 L21.4666667,34.4003333 C22.4845702,34.5332189 23.5154298,34.5332189 24.5333333,34.4003333 Z" id="Shape" fill="#898890">

                    </path>
                    <path d="M31.4333333,41.4 L31.4333333,42.9333333 C31.4308073,43.7791214 30.7457881,44.4641407 29.9,44.4666667 L16.1,44.4666667 C15.2542119,44.4641407 14.5691927,43.7791214 14.5666667,42.9333333 L14.5666667,41.4 C14.5691927,40.5542119 15.2542119,39.8691927 16.1,39.8666667 L29.9,39.8666667 C30.7457881,39.8691927 31.4308073,40.5542119 31.4333333,41.4 Z" id="Shape" fill="#898890">

                    </path>
                    <path d="M24.5333333,28.3666667 L24.5333333,30.5286667 C23.5193928,30.7126852 22.4806072,30.7126852 21.4666667,30.5286667 L21.4666667,28.3666667 L24.5333333,28.3666667 Z" id="Shape" fill="#898890">

                    </path>
                    <circle id="Oval" fill="#FF5023" cx="4.6" cy="3.06666667" r="1.53333333">

                    </circle>
                    <circle id="Oval" fill="#FF5023" cx="4.6" cy="18.4" r="1.53333333">

                    </circle>
                    <circle id="Oval" fill="#FF5023" cx="2.3" cy="10.7333333" r="1.53333333">

                    </circle>
                    <path d="M32.9666667,18.4 L32.9666667,19.9333333 C32.9641407,20.7791214 32.2791214,21.4641407 31.4333333,21.4666667 L14.5666667,21.4666667 C13.7208786,21.4641407 13.0358593,20.7791214 13.0333333,19.9333333 L13.0333333,18.4 C13.0358593,17.5542119 13.7208786,16.8691927 14.5666667,16.8666667 L31.4333333,16.8666667 C32.2791214,16.8691927 32.9641407,17.5542119 32.9666667,18.4 Z" id="Shape" fill="#898890">

                    </path>
                    <path d="M31.4333333,8.43333333 L31.4333333,16.8666667 L14.5666667,16.8666667 L14.5666667,8.43333333 C14.5666667,8.17266667 14.582,7.91966667 14.605,7.66666667 C14.628,7.41366667 14.6586667,7.153 14.7046667,6.9 C14.80242,6.37643174 14.9487184,5.86310437 15.1416667,5.36666667 C15.2409395,5.10432088 15.3561543,4.84828797 15.4866667,4.6 C15.6225902,4.33596656 15.7736184,4.07998655 15.939,3.83333333 C17.49183,1.44242996 20.1490879,-0.000262740168 23,-0.000262740168 C25.8509121,-0.000262740168 28.50817,1.44242996 30.061,3.83333333 C30.2263816,4.07998655 30.3774098,4.33596656 30.5133333,4.6 C30.6438457,4.84828797 30.7590605,5.10432088 30.8583333,5.36666667 C31.0512816,5.86310437 31.19758,6.37643174 31.2953333,6.9 C31.3413333,7.153 31.372,7.406 31.395,7.66666667 C31.418,7.92733333 31.4333333,8.17266667 31.4333333,8.43333333 Z" id="Shape" fill="#FF9811">

                    </path>
                    <path d="M31.395,21.4666667 C31.351105,21.9852779 31.2587009,22.4986338 31.119,23 C30.0921768,26.6315035 26.7777152,29.1387351 23.0038333,29.1387351 C19.2299515,29.1387351 15.9154899,26.6315035 14.8886667,23 C14.7464088,22.498875 14.6514378,21.9855184 14.605,21.4666667 L31.395,21.4666667 Z" id="Shape" fill="#EE8700">

                    </path>
                    <path d="M31.395,21.4666667 C31.351105,21.9852779 31.2587009,22.4986338 31.119,23 L14.8886667,23 C14.7464088,22.498875 14.6514378,21.9855184 14.605,21.4666667 L31.395,21.4666667 Z" id="Shape" fill="#CC7400">

                    </path>
                    <path d="M38.249,22.2026667 C38.250272,22.2564473 38.24771,22.3102502 38.2413333,22.3636667 C37.4453395,29.5584639 31.734581,35.2213133 24.5333333,35.9566667 C23.5136667,36.0588889 22.4863333,36.0588889 21.4666667,35.9566667 C14.265419,35.2213133 8.5546605,29.5584639 7.75866667,22.3636667 C7.71857057,21.9354284 7.86155876,21.5102208 8.15226169,21.1932231 C8.44296462,20.8762254 8.85423221,20.6970436 9.28433333,20.7 C10.0775757,20.7068499 10.7368408,21.3131088 10.81,22.103 C11.4669721,27.7133973 15.8631348,32.1538378 21.4666667,32.867 C22.4845702,32.9998856 23.5154298,32.9998856 24.5333333,32.867 C30.1368652,32.1538378 34.5330279,27.7133973 35.19,22.103 C35.2631592,21.3131088 35.9224243,20.7068499 36.7156667,20.7 C37.5521822,20.6961174 38.2359843,21.3662434 38.249,22.2026667 Z" id="Shape" fill="#C6C5CA">

                    </path>
                    <g id="Group" transform="translate(14.566667, 3.833333)" fill="#EE8700">
                        <polygon id="Shape" points="10.7333333 9.2 16.8666667 9.2 16.8666667 10.7333333 10.7333333 10.7333333">

                        </polygon>
                        <polygon id="Shape" points="10.7333333 6.13333333 16.8666667 6.13333333 16.8666667 7.66666667 10.7333333 7.66666667">

                        </polygon>
                        <path d="M16.8666667,4.6 L10.7333333,4.6 L10.7333333,3.06666667 L16.7286667,3.06666667 C16.7746667,3.31966667 16.8053333,3.57266667 16.8283333,3.83333333 C16.8513333,4.094 16.8666667,4.33933333 16.8666667,4.6 Z" id="Shape">

                        </path>
                        <path d="M16.2916667,1.53333333 L10.7333333,1.53333333 L10.7333333,0 L15.4943333,0 C15.6597149,0.246653218 15.8107431,0.502633228 15.9466667,0.766666667 C16.0771791,1.01495464 16.1923939,1.27098754 16.2916667,1.53333333 Z" id="Shape">

                        </path>
                        <polygon id="Shape" points="0 9.2 6.13333333 9.2 6.13333333 10.7333333 0 10.7333333">

                        </polygon>
                        <polygon id="Shape" points="0 6.13333333 6.13333333 6.13333333 6.13333333 7.66666667 0 7.66666667">

                        </polygon>
                        <path d="M6.13333333,3.06666667 L6.13333333,4.6 L0,4.6 C0,4.33933333 0.0153333333,4.08633333 0.0383333333,3.83333333 C0.0613333333,3.58033333 0.092,3.31966667 0.138,3.06666667 L6.13333333,3.06666667 Z" id="Shape">

                        </path>
                        <path d="M6.13333333,-3.55271368e-15 L6.13333333,1.53333333 L0.575,1.53333333 C0.674272802,1.27098754 0.789487609,1.01495464 0.92,0.766666667 C1.05592356,0.502633228 1.20695177,0.246653218 1.37233333,-3.55271368e-15 L6.13333333,-3.55271368e-15 Z" id="Shape">

                        </path>
                    </g>
                    <path d="M36.8,8.43333333 L33.7333333,8.43333333 L33.7333333,6.9 L36.4826,6.9 L38.5579667,4.82463333 L39.6420333,5.9087 L37.3420333,8.2087 C37.1982889,8.35248785 37.003316,8.43328991 36.8,8.43333333 Z" id="Shape" fill="#FFA733">

                    </path>
                    <path d="M38.5579667,16.6420333 L36.4826,14.5666667 L33.7333333,14.5666667 L33.7333333,13.0333333 L36.8,13.0333333 C37.003316,13.0333768 37.1982889,13.1141788 37.3420333,13.2579667 L39.6420333,15.5579667 L38.5579667,16.6420333 Z" id="Shape" fill="#FFA733">

                    </path>
                    <polygon id="Shape" fill="#FFA733" points="33.7333333 9.96666667 39.8666667 9.96666667 39.8666667 11.5 33.7333333 11.5">

                    </polygon>
                    <path d="M12.2666667,8.43333333 L9.2,8.43333333 C8.99668398,8.43328991 8.80171109,8.35248785 8.65796667,8.2087 L6.35796667,5.9087 L7.44203333,4.82463333 L9.5174,6.9 L12.2666667,6.9 L12.2666667,8.43333333 Z" id="Shape" fill="#FFA733">

                    </path>
                    <path d="M7.44203333,16.6420333 L6.35796667,15.5579667 L8.65796667,13.2579667 C8.80171109,13.1141788 8.99668398,13.0333768 9.2,13.0333333 L12.2666667,13.0333333 L12.2666667,14.5666667 L9.5174,14.5666667 L7.44203333,16.6420333 Z" id="Shape" fill="#FFA733">

                    </path>
                    <polygon id="Shape" fill="#FFA733" points="6.13333333 9.96666667 12.2666667 9.96666667 12.2666667 11.5 6.13333333 11.5">

                    </polygon>
                    <path d="M41.4,5.36666667 C40.1297451,5.36666667 39.1,4.33692159 39.1,3.06666667 C39.1,1.79641174 40.1297451,0.766666667 41.4,0.766666667 C42.6702549,0.766666667 43.7,1.79641174 43.7,3.06666667 C43.7,4.33692159 42.6702549,5.36666667 41.4,5.36666667 Z M41.4,2.3 C40.9765817,2.3 40.6333333,2.64324836 40.6333333,3.06666667 C40.6333333,3.49008497 40.9765817,3.83333333 41.4,3.83333333 C41.8234183,3.83333333 42.1666667,3.49008497 42.1666667,3.06666667 C42.1666667,2.64324836 41.8234183,2.3 41.4,2.3 Z" id="Shape" fill="#FF9811">

                    </path>
                    <path d="M43.7,13.0333333 C42.4297451,13.0333333 41.4,12.0035883 41.4,10.7333333 C41.4,9.46307841 42.4297451,8.43333333 43.7,8.43333333 C44.9702549,8.43333333 46,9.46307841 46,10.7333333 C46,12.0035883 44.9702549,13.0333333 43.7,13.0333333 Z M43.7,9.96666667 C43.2765817,9.96666667 42.9333333,10.309915 42.9333333,10.7333333 C42.9333333,11.1567516 43.2765817,11.5 43.7,11.5 C44.1234183,11.5 44.4666667,11.1567516 44.4666667,10.7333333 C44.4666667,10.309915 44.1234183,9.96666667 43.7,9.96666667 Z" id="Shape" fill="#FF9811">

                    </path>
                    <path d="M41.4,20.7 C40.1297451,20.7 39.1,19.6702549 39.1,18.4 C39.1,17.1297451 40.1297451,16.1 41.4,16.1 C42.6702549,16.1 43.7,17.1297451 43.7,18.4 C43.7,19.6702549 42.6702549,20.7 41.4,20.7 Z M41.4,17.6333333 C40.9765817,17.6333333 40.6333333,17.9765817 40.6333333,18.4 C40.6333333,18.8234183 40.9765817,19.1666667 41.4,19.1666667 C41.8234183,19.1666667 42.1666667,18.8234183 42.1666667,18.4 C42.1666667,17.9765817 41.8234183,17.6333333 41.4,17.6333333 Z" id="Shape" fill="#FF9811">

                    </path>
                    <path d="M4.6,5.36666667 C3.32974508,5.36666667 2.3,4.33692159 2.3,3.06666667 C2.3,1.79641174 3.32974508,0.766666667 4.6,0.766666667 C5.87025492,0.766666667 6.9,1.79641174 6.9,3.06666667 C6.9,4.33692159 5.87025492,5.36666667 4.6,5.36666667 Z M4.6,2.3 C4.17658169,2.3 3.83333333,2.64324836 3.83333333,3.06666667 C3.83333333,3.49008497 4.17658169,3.83333333 4.6,3.83333333 C5.02341831,3.83333333 5.36666667,3.49008497 5.36666667,3.06666667 C5.36666667,2.64324836 5.02341831,2.3 4.6,2.3 Z" id="Shape" fill="#FF9811">

                    </path>
                    <path d="M2.3,13.0333333 C1.02974508,13.0333333 -1.03620816e-16,12.0035883 -4.4408921e-16,10.7333333 C-7.84557604e-16,9.46307841 1.02974508,8.43333333 2.3,8.43333333 C3.57025492,8.43333333 4.6,9.46307841 4.6,10.7333333 C4.6,12.0035883 3.57025492,13.0333333 2.3,13.0333333 Z M2.3,9.96666667 C1.87658169,9.96666667 1.53333333,10.309915 1.53333333,10.7333333 C1.53333333,11.1567516 1.87658169,11.5 2.3,11.5 C2.72341831,11.5 3.06666667,11.1567516 3.06666667,10.7333333 C3.06666667,10.309915 2.72341831,9.96666667 2.3,9.96666667 Z" id="Shape" fill="#FF9811">

                    </path>
                    <path d="M4.6,20.7 C3.32974508,20.7 2.3,19.6702549 2.3,18.4 C2.3,17.1297451 3.32974508,16.1 4.6,16.1 C5.87025492,16.1 6.9,17.1297451 6.9,18.4 C6.9,19.6702549 5.87025492,20.7 4.6,20.7 Z M4.6,17.6333333 C4.17658169,17.6333333 3.83333333,17.9765817 3.83333333,18.4 C3.83333333,18.8234183 4.17658169,19.1666667 4.6,19.1666667 C5.02341831,19.1666667 5.36666667,18.8234183 5.36666667,18.4 C5.36666667,17.9765817 5.02341831,17.6333333 4.6,17.6333333 Z" id="Shape" fill="#FF9811">

                    </path>
                    <path d="M21.2113667,26.5266667 C20.5509928,26.2935901 19.9429651,25.9327852 19.4219667,25.4648333 L20.4447,24.3225 C20.816432,24.6566206 21.2504926,24.9140347 21.7219667,25.0799667 L21.2113667,26.5266667 Z" id="Shape" fill="#FFC477">

                    </path>
                    <path d="M23,26.8333333 L23,25.3 C24.3691442,25.2972076 25.6337661,24.5673934 26.3212,23.3833333 L27.6483,24.15 C26.6864155,25.8075346 24.9164098,26.8293093 23,26.8333333 Z" id="Shape" fill="#FFC477">

                    </path>
                </g>
            </g>
        </svg>
    )
}

export default SpeechRecognition;