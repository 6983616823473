import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import ai from "../../assets/ai_solutions_for_startups.jpg";
import HomeAnimation from "./HomeAnimation";

const Hero = () => {
  const [ref3, inView3] = useInView();
  const controls2 = useAnimation();

  useEffect(() => {
    if (inView3) {
      controls2.start({ x: 0 });
      controls2.start({ y: 0 });
    }
  }, [controls2, inView3]);

  return (
    <section className="h-screen w-full overflow-hidden xl:container flex items-center">
      <div className=" flex w-full lg:flex-row items-center sm:pt-32 lg:pt-0 gap-3.5 sm:flex-col ">
        <div className="flex flex-column sm:pl-[15%] lg:pl-0 lg:ml-32 sm:w-full sm:mx-4 lg:w-4/5 items-center">
          <div className="flex-row w-full ">
            <HomeAnimation />
            <motion.svg
              // width="220"
              // height="16"
              viewBox="0 0 315 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="-top-2 z-0 relative -mt-4 lg:w-[170px] sm:w-[130px] h-[16px]"
            >
              <motion.path
                initial={{ pathLength: 0 }}
                animate={{ pathLength: 1 }}
                transition={{
                  duration: 1,
                  ease: "easeInOut",
                  repeat: Infinity,
                  // repeatType: "loop",
                  repeatDelay: 10,
                }}
                d="M3.83809 12.7316C56.6479 -0.396179 180.819 0.105158 311.781 12.8874"
                stroke="#3E91EE"
                strokeWidth="6"
                strokeLinecap="round"
              />
            </motion.svg>
            {/* <h2 className="text-xl font-medium">
                
              </h2> */}
            <p className="mt-2 lg:text-xl sm:text-sm order-1 font-base leading-7 object-none tracking-tight">
              Unlock Opportunities with Ai as a <br /> Service Solutions
            </p>
          </div>
        </div>
        <div className="m-0 flex flex-col container items-center justify-center sm:mt-8">
          <div className="relative z-10 lg:right-8 lg:top-20 sm:right-0 sm:top-0">
            <img
              src={ai}
              alt="Ai based solutions that grow business, being discussed by two people at Factacy"
              className="lg:w-[24rem] lg:h-[28rem] sm:h-96 sm:w-[60rem] rounded-xl object-cover"
            ></img>
          </div>

          <div className="flex flex-col relative z-20" ref={ref3}>
            <motion.div
              animate={controls2}
              initial={{ x: "100%" }}
              transition={{ delay: 0.2, duration: 0.5 }}
              whileInView="visible"
              className="flex flex-row gap-2.5 w-fit h-fit lg:px-5 lg:py-3 sm:px-3 sm:py-2 rounded-full relative lg:left-40 sm:left-20 lg:bottom-[320px] sm:bottom-[280px]"
              style={{ backgroundColor: "#DDBAFF" }}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 18H18V6H6V18ZM14 20H10V22H8V20H5C4.44772 20 4 19.5523 4 19V16H2V14H4V10H2V8H4V5C4 4.44772 4.44772 4 5 4H8V2H10V4H14V2H16V4H19C19.5523 4 20 4.44772 20 5V8H22V10H20V14H22V16H20V19C20 19.5523 19.5523 20 19 20H16V22H14V20ZM8 8H16V16H8V8Z"
                  fill="#F4E8FF"
                />
                <path
                  d="M6 18H18V6H6V18ZM14 20H10V22H8V20H5C4.44772 20 4 19.5523 4 19V16H2V14H4V10H2V8H4V5C4 4.44772 4.44772 4 5 4H8V2H10V4H14V2H16V4H19C19.5523 4 20 4.44772 20 5V8H22V10H20V14H22V16H20V19C20 19.5523 19.5523 20 19 20H16V22H14V20Z"
                  fill="#8000FF"
                />
              </svg>
              <p className="font-normal lg:text-base sm:text-sm">
                NLP & Advanced Analysis
              </p>
            </motion.div>

            <motion.div
              animate={controls2}
              initial={{ y: "400%" }}
              transition={{ delay: 0.2, duration: 0.5 }}
              whileInView="visible"
              className="flex flex-row gap-2.5 bg-green w-fit h-fit lg:px-5 lg:py-3 sm:px-3 sm:py-2 rounded-full relative lg:right-36 sm:right-20 bottom-60"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21 9.5V12.5C21 14.9853 16.9706 17 12 17C7.02944 17 3 14.9853 3 12.5V9.5C3 11.9853 7.02944 14 12 14C16.9706 14 21 11.9853 21 9.5ZM3 14.5C3 16.9853 7.02944 19 12 19C16.9706 19 21 16.9853 21 14.5V17.5C21 19.9853 16.9706 22 12 22C7.02944 22 3 19.9853 3 17.5V14.5ZM12 12C7.02944 12 3 9.98528 3 7.5C3 5.01472 7.02944 3 12 3C16.9706 3 21 5.01472 21 7.5C21 9.98528 16.9706 12 12 12Z"
                  fill="#8BC499"
                />
                <path
                  d="M21 9.5V12.5C21 14.9853 16.9706 17 12 17C7.02944 17 3 14.9853 3 12.5V9.5C3 11.9853 7.02944 14 12 14C16.9706 14 21 11.9853 21 9.5ZM3 14.5C3 16.9853 7.02944 19 12 19C16.9706 19 21 16.9853 21 14.5V17.5C21 19.9853 16.9706 22 12 22C7.02944 22 3 19.9853 3 17.5V14.5Z"
                  fill="#F5FFF8"
                />
              </svg>

              <p className="font-normal lg:text-base sm:text-sm">
                Ai Powered Datasets
              </p>
            </motion.div>

            <motion.div
              animate={controls2}
              initial={{ y: "100%" }}
              transition={{ delay: 0.2, duration: 0.5 }}
              whileInView="visible"
              className="flex flex-row gap-2.5 bg-yellow w-fit h-fit lg:px-5 lg:py-3 sm:px-3 sm:py-2 rounded-full relative lg:left-40 sm:left-32 lg:bottom-12 sm:bottom-28"
            >
              <svg
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.8955 16.637C18.5524 16.637 19.8955 17.9801 19.8955 19.637C19.8955 21.2939 18.5524 22.637 16.8955 22.637C15.2386 22.637 13.8955 21.2939 13.8955 19.637C13.8955 17.9801 15.2386 16.637 16.8955 16.637ZM6.89551 12.637C9.10465 12.637 10.8955 14.4279 10.8955 16.637C10.8955 18.8461 9.10465 20.637 6.89551 20.637C4.68637 20.637 2.89551 18.8461 2.89551 16.637C2.89551 14.4279 4.68637 12.637 6.89551 12.637ZM15.3955 2.63696C18.4331 2.63696 20.8955 5.09939 20.8955 8.13696C20.8955 11.1746 18.4331 13.637 15.3955 13.637C12.3579 13.637 9.89551 11.1746 9.89551 8.13696C9.89551 5.09939 12.3579 2.63696 15.3955 2.63696Z"
                  fill="#FAE243"
                />
                <path
                  d="M6.89551 12.637C9.10465 12.637 10.8955 14.4279 10.8955 16.637C10.8955 18.8461 9.10465 20.637 6.89551 20.637C4.68637 20.637 2.89551 18.8461 2.89551 16.637C2.89551 14.4279 4.68637 12.637 6.89551 12.637ZM15.3955 2.63696C18.4331 2.63696 20.8955 5.09939 20.8955 8.13696C20.8955 11.1746 18.4331 13.637 15.3955 13.637C12.3579 13.637 9.89551 11.1746 9.89551 8.13696C9.89551 5.09939 12.3579 2.63696 15.3955 2.63696Z"
                  fill="#FAE243"
                />
              </svg>
              <p className="font-normal lg:text-base sm:text-sm">
                Analytics of Things
              </p>
            </motion.div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
