import React from "react";

const ExcellenceIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="65"
      viewBox="0 0 64 65"
      fill="none"
    >
      <path
        d="M37.6575 28.3901C36.6161 27.3488 34.9276 27.3488 33.8863 28.3901L32.0007 30.276C29.9177 32.3586 26.5409 32.3586 24.4581 30.276C22.3753 28.193 22.3753 24.8162 24.4581 22.7334L39.4732 7.71428C45.0836 6.44508 51.2028 7.99447 55.5708 12.3625C62.0225 18.8141 62.3249 29.0864 56.4785 35.8962L50.8567 41.5896L37.6575 28.3901ZM8.43033 12.3625C14.226 6.56674 23.1049 5.73335 29.7873 9.86223L20.6868 18.9621C16.5213 23.1277 16.5213 29.8816 20.6868 34.0472C24.7262 38.0864 31.1993 38.2088 35.386 34.4144L35.7719 34.0472L47.0854 45.3608L35.7719 56.6744C33.6889 58.7573 30.3121 58.7573 28.2295 56.6744L8.43033 36.8754C1.66124 30.1064 1.66124 19.1315 8.43033 12.3625Z"
        fill="#E8F5FF"
      />
      <path
        d="M8.43033 12.3625C14.226 6.56674 23.1049 5.73335 29.7873 9.86223L20.6868 18.9621C16.5213 23.1277 16.5213 29.8816 20.6868 34.0472C24.7262 38.0864 31.1993 38.2088 35.386 34.4144L35.7719 34.0472L47.0854 45.3608L35.7719 56.6744C33.6889 58.7573 30.3121 58.7573 28.2295 56.6744L8.43033 36.8754C1.66124 30.1064 1.66124 19.1315 8.43033 12.3625Z"
        fill="#3E91EE"
      />
    </svg>
  );
};

export default ExcellenceIcon;
