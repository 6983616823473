// import React, { useEffect, useState } from "react";

// const HomeAnimation = () => {
//   const tailwindClasses = [
//     "-translate-y-[0%]",
//     "-translate-y-[100%]",
//     "-translate-y-[200%]",
//     "-translate-y-[300%]",
//     "-translate-y-[0%]",
//     "-translate-y-[25%]",
//     "-translate-y-[50%]",
//     "-translate-y-[75%]",
//   ];
//   // const servicesOptions = ["GEN AI", "GPTs", "Computer vision", "Technology"];
//   const [servicesOptions, setServicesOptions] = useState([
//     "GEN AI",
//     "GPTs",
//     "Computer vision",
//     "Technology",
//   ]);
//   const [optionsShowNumber, setOptionsShowNuber] = useState(1);
//   useEffect(() => {
//     const id = setInterval(() => {
//       setOptionsShowNuber((pre) => (pre === 4 ? 1 : pre + 1));
//       let list = Array.from(servicesOptions);
//       // let ele = list.shift();
//       // list.push(ele)
//       list.concat(servicesOptions);
//       setServicesOptions(list);
//     }, 2000);

//     return () => clearInterval(id);
//   }, []);
//   return (
//     <>
//       <div className="flex items-start gap-2 h-[3rem] lg:h-[3rem] xl:h-[4rem] overflow-hidden ">
//         <div className="h-full flex items-center">
//           <h1 className="sm:text-2xl lg:text-3xl xl:text-4xl font-medium">
//             We make
//           </h1>
//         </div>
//         <div
//           style={{ transition: "all ease-in-out 1s" }}
//           className={` h-[400%] flex flex-col -translate-y-[${
//             25 * optionsShowNumber - 25
//           }%]`}
//         >
//           {servicesOptions.map((item, index) => (
//             <span
//               key={index}
//               className={`h-[25%] font-medium text-[#3E91EE] flex items-center pt-[0.05rem] text-2xl lg:text-3xl xl:text-4xl`}
//             >
//               {item}
//             </span>
//           ))}
//         </div>
//       </div>
//       <h1 className="sm:text-3xl lg:text-4xl font-semibold mb-5 relative z-20">
//         work for you
//       </h1>
//     </>
//   );
// };

// export default HomeAnimation;

import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import "./styles.css";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";

const HomeAnimation = () => {
  return (
    <>
      <div className="flex items-start gap-2 h-[3rem] lg:h-[3rem] xl:h-[4rem]">
        <div className="h-full flex min-w-fit items-center">
        <h1 className="sm:text-2xl lg:text-3xl xl:text-4xl font-medium ">
          We make
        </h1>
        </div>
        <Swiper
          direction={"vertical"}
          loop={true}
          // spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 2000,
            disableOnInteraction: false,
          }}
          modules={[Autoplay, Pagination, Navigation]}
          className="mySwiper"
        >
          <SwiperSlide>
            {" "}
            <span className="font-medium text-[#3E91EE] flex items-center pt-[0.05rem] text-2xl lg:text-3xl xl:text-4xl">
              GEN AI
            </span>
          </SwiperSlide>
          <SwiperSlide>
            {" "}
            <span className="font-medium text-[#3E91EE] flex items-center pt-[0.05rem] text-2xl lg:text-3xl xl:text-4xl">
              GPTs
            </span>
          </SwiperSlide>
          <SwiperSlide>
            {" "}
            <span className="font-medium text-[#3E91EE] flex items-center pt-[0.05rem] text-2xl lg:text-3xl xl:text-4xl">
              Computer vision
            </span>
          </SwiperSlide>
          <SwiperSlide>
            {" "}
            <span className="font-medium text-[#3E91EE] flex items-center pt-[0.05rem] text-2xl lg:text-3xl xl:text-4xl">
              Technology
            </span>
          </SwiperSlide>
        </Swiper>
        {/* {} */}
      </div>
      <h1 className="text-2xl lg:text-3xl xl:text-4xl font-semibold mb-5 relative z-20">
        work for you
      </h1>
    </>
  );
};

export default HomeAnimation;
