import React from "react";

const AiIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="62"
      height="62"
      viewBox="0 0 62 62"
      fill="none"
    >
      <path
        d="M15.5007 46.0109H46.0007V15.5109H15.5007V46.0109ZM35.834 51.0942H25.6673V56.1776H20.584V51.0942H12.959C11.5553 51.0942 10.4173 49.9563 10.4173 48.5526V40.9276H5.33398V35.8442H10.4173V25.6776H5.33398V20.5942H10.4173V12.9692C10.4173 11.5655 11.5553 10.4276 12.959 10.4276H20.584V5.34424H25.6673V10.4276H35.834V5.34424H40.9173V10.4276H48.5423C49.9461 10.4276 51.084 11.5655 51.084 12.9692V20.5942H56.1673V25.6776H51.084V35.8442H56.1673V40.9276H51.084V48.5526C51.084 49.9563 49.9461 51.0942 48.5423 51.0942H40.9173V56.1776H35.834V51.0942ZM20.584 20.5942H40.9173V40.9276H20.584V20.5942Z"
        fill="#B5EEC3"
      />
      <path
        d="M15.5007 46.0109H46.0006V15.5109H15.5007V46.0109ZM35.834 51.0942H25.6673V56.1776H20.584V51.0942H12.959C11.5553 51.0942 10.4173 49.9563 10.4173 48.5526V40.9276H5.33398V35.8442H10.4173V25.6776H5.33398V20.5942H10.4173V12.9692C10.4173 11.5655 11.5553 10.4276 12.959 10.4276H20.584V5.34424H25.6673V10.4276H35.834V5.34424H40.9173V10.4276H48.5423C49.9461 10.4276 51.084 11.5655 51.084 12.9692V20.5942H56.1673V25.6776H51.084V35.8442H56.1673V40.9276H51.084V48.5526C51.084 49.9563 49.9461 51.0942 48.5423 51.0942H40.9173V56.1776H35.834V51.0942Z"
        fill="#649771"
      />
    </svg>
  );
};

export default AiIcon;
