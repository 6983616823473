import React from 'react'
import {useState} from 'react'
import axios from 'axios'
import FormData from "form-data";
import chat from '../../assets/chat gif.png'

const ChatBot = ({openChatBot}) => {
  const [user_prompt, setUserPrompt] = useState('')
  const [chatHistory, setChatHistory] = useState([])
  const handleChange = user_prompt => {
    setUserPrompt(user_prompt)
    clearInterval()
  };
  console.log(user_prompt)
  const handleUserMessage = () => {
    const userMessage = {sender: 'user', message: user_prompt};
    setChatHistory(prevHistory => [...prevHistory, userMessage])
  }

  let formData = new FormData();
  formData.append('user_prompt', user_prompt);
  const getResponse = async (e) => {
    try {
      e.preventDefault()
      handleUserMessage()
      setUserPrompt('')
      const response = await axios.post('http://216.48.176.248:5000/api/prompt_route', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
  const apiResponse = response.data
  const botResponse = apiResponse.Answer
  const botMessage = {sender: 'bot', message: botResponse};

  setChatHistory(prevHistory => [...prevHistory, botMessage])

  console.log(botResponse)
}
    catch(error){
  console.log("error fetching", error)
}
}


  return (
    <div className={`font-roboto h-screen lg:px-12 sm:px-4 bg-gradient-to-b from-white to-blue fixed w-screen shadow-md animate-chatbot`}>

      <div className='flex flex-col-reverse h-screen lg:pb-28 sm:pb-20 justify-between lg:items-center  gap-12'>

      <div className={`lg:w-2/3 sm:w-11/12 bg-white rounded-full absolute px-3 py-2 transition-transform duration-1000 ease-in-out shadow-md ${openChatBot ? 'translate-y-1/2' : 'translate-y-0'}`} style={{boxShadow:"0px 0.3px 0.9px rgba(0, 0, 0, 0.12), 0px 1.6px 3.6px rgba(0, 0, 0, 0.16)"}}>
          <form onSubmit={getResponse} className='flex items-center gap-2'>
            <img className='w-5 h-5' alt='chat' src={chat} />
          <input  className='w-full resize-y appearance-none outline-none placeholder:text-xs' required type='text' onChange={(e) => handleChange(e.target.value)} value={user_prompt} aria-label='Input for prompt text' placeholder='Ask me anything...'>
          </input>
          <button type='submit' className='w-4 h-4 cursor-pointer' onClick={getResponse}>
            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.8147 12.1969L5.28344 13.4521C5.10705 13.4815 4.95979 13.6029 4.89723 13.7704L2.29933 20.7278C2.05066 21.3673 2.72008 21.9773 3.33375 21.6705L21.3337 12.6705C21.8865 12.3941 21.8865 11.6052 21.3337 11.3288L3.33375 2.32885C2.72008 2.02201 2.05066 2.63206 2.29933 3.2715L4.89723 10.2289C4.95979 10.3964 5.10705 10.5178 5.28344 10.5472L12.8147 11.8024C12.9236 11.8205 12.9972 11.9236 12.9791 12.0325C12.965 12.1168 12.899 12.1829 12.8147 12.1969Z"></path>
            </svg>
          </button>
          </form>
      </div >

      <div className='lg:w-2/3 sm:w-full overflow-y-scroll mb-14 justify-end'>

          {chatHistory.map((message, index)=> (
          <div className='flex flex-col p-1 gap-4 w-full'>
          <p className={`text-base ${message.sender === 'user' ? 'bg-primaryBlue' : 'bg-white'} ${message.sender === 'user' ? 'rounded-l-xl rounded-tr-xl' : 'rounded-r-xl rounded-tl-xl' } max-w-fit break-normal px-2 py-1 ${message.sender === 'user' ? 'text-white' : 'text-black'} ${message.sender === 'user' ? 'self-end' : 'self-start'}`} 
          key={index}
          style={{boxShadow:"0px 0.3px 0.9px rgba(0, 0, 0, 0.12), 0px 1.6px 3.6px rgba(0, 0, 0, 0.16)"}}>
            {message.message}
          </p>
          </div>
          ))}

        </div>
      </div>
    </div>
  )
}

export default ChatBot