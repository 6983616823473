import React from "react";
import { motion } from "framer-motion";

import LogoCarousel from "./logoCarousel";
import AiAsService from "./aiAsService";
import AnimatedCard from "./AnimatedCard";
import WeInNews from "./WeInNews";
import Testimonials from "./Testimonials";
import Team from "./Team";
import Hero from "./Hero";
import AOT_Section from "./AOT_Section";

const Homepage = () => {
  return (
    <motion.div
      className="overflow-hidden"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.75, ease: "easeOut" }}
    >
      {/* Hero Section */}
      <Hero />

      {/* SVGS */}
      <LogoCarousel />

      {/* <Cards /> */}
      <AnimatedCard />

      <AOT_Section />
      
      {/* Ai as a Service */}
      <AiAsService />

      {/* Our Team */}
      <Team />

      {/* Testimonials */}
      <Testimonials />

      {/* We In The News */}
      <WeInNews />
    </motion.div>
  );
};
export default Homepage;
