import React from 'react'

const DataIntelligence = () => {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            width="200.000000pt" height="200.000000pt" viewBox="0 0 200.000000 200.000000"
            preserveAspectRatio="xMidYMid meet" className="h-12 w-12">

            <g transform="translate(0.000000,200.000000) scale(0.100000,-0.100000)"
                fill="#000000" stroke="none">
                <path d="M1730 1788 c-26 -14 -50 -36 -59 -54 -15 -29 -20 -32 -82 -37 -90 -8
-124 -24 -215 -103 -44 -37 -81 -69 -83 -70 -2 -2 6 -16 18 -32 16 -24 18 -32
8 -40 -25 -21 -105 -32 -247 -32 l-145 0 -17 -29 c-35 -62 -116 -86 -177 -54
-101 53 -106 184 -9 243 63 38 149 15 186 -50 l17 -30 155 0 c154 0 200 5 200
20 0 38 -162 155 -261 190 -89 31 -259 39 -349 16 -222 -57 -391 -223 -465
-459 -18 -56 -20 -76 -12 -111 9 -42 9 -43 -62 -146 -53 -77 -71 -112 -71
-136 0 -36 31 -74 61 -74 45 0 49 -8 43 -84 -5 -57 -4 -74 9 -84 12 -10 14
-26 10 -72 -11 -112 35 -160 161 -167 39 -3 90 0 112 5 l42 9 18 -71 19 -71
314 -3 c232 -2 317 1 322 9 4 7 7 42 7 78 -1 36 3 85 7 109 l7 42 -134 0 -133
0 -17 -29 c-50 -90 -176 -90 -235 -2 -45 66 -18 155 57 194 62 32 143 8 178
-53 20 -34 34 -36 193 -32 l116 4 29 70 c15 39 34 72 40 75 15 5 49 -11 87
-43 15 -13 46 -29 68 -35 30 -9 44 -21 56 -47 25 -51 67 -74 131 -69 150 11
179 208 38 262 -35 13 -51 15 -84 6 -37 -10 -92 -59 -92 -82 0 -12 -49 8 -87
37 -17 13 -43 28 -58 34 -23 9 -28 6 -45 -23 -19 -31 -21 -32 -82 -29 -61 3
-64 2 -80 -27 -50 -90 -186 -94 -238 -8 -38 62 -17 152 45 193 59 39 157 12
192 -53 17 -32 19 -33 90 -34 40 -1 79 -2 86 -3 6 -1 30 34 51 77 l40 77 132
0 132 0 20 -33 c21 -37 75 -67 117 -67 37 0 98 36 118 70 23 39 22 105 -3 145
-22 36 -73 65 -115 65 -42 0 -96 -31 -117 -67 l-20 -33 -124 0 -123 0 -11 -40
-11 -40 -263 0 -264 0 -20 -33 c-34 -58 -113 -82 -170 -53 -57 30 -77 63 -77
126 0 63 20 96 77 126 57 29 136 5 170 -53 l20 -33 273 0 272 0 6 46 c5 48 -2
164 -10 164 -3 0 -17 -8 -31 -18 -42 -30 -90 -42 -161 -42 -63 -1 -67 -2 -86
-32 -31 -49 -68 -71 -119 -71 -76 0 -136 60 -136 136 0 141 188 189 258 67 16
-28 21 -30 73 -30 43 0 70 7 115 30 32 16 62 30 66 30 3 0 9 -13 13 -30 4 -16
8 -30 10 -30 1 0 19 7 39 15 20 8 36 11 36 6 0 -5 12 -23 26 -40 92 -110 280
-5 235 132 -19 56 -62 90 -123 94 -65 5 -107 -18 -132 -71 -9 -20 -22 -36 -29
-36 -7 0 -25 -7 -42 -16 -21 -10 -31 -12 -33 -4 -2 6 -16 35 -31 65 l-27 53
69 63 c87 79 127 99 194 99 48 0 54 -3 68 -29 35 -69 129 -99 195 -63 109 59
107 212 -3 262 -54 25 -73 25 -127 -2z m119 -84 c12 -15 21 -34 21 -44 0 -34
-39 -70 -75 -70 -36 0 -75 36 -75 70 0 34 39 70 75 70 24 0 39 -7 54 -26z
m-189 -324 c41 -41 13 -110 -44 -110 -53 0 -78 84 -34 114 30 21 54 20 78 -4z
m200 -352 c13 -25 13 -31 0 -55 -21 -38 -81 -45 -104 -11 -9 12 -16 29 -16 38
0 9 7 26 16 38 23 34 83 27 104 -10z m-202 -314 c39 -27 24 -96 -24 -108 -39
-10 -74 17 -74 56 0 59 50 86 98 52z"/>
                <path d="M746 1504 c-22 -21 -20 -67 2 -87 40 -37 102 -9 102 46 0 34 -24 57
-60 57 -16 0 -36 -7 -44 -16z"/>
                <path d="M975 1265 c-30 -29 -31 -45 -4 -79 25 -31 61 -34 89 -6 33 33 26 76
-17 99 -31 16 -41 14 -68 -14z"/>
                <path d="M686 1038 c-21 -30 -20 -54 4 -78 41 -41 110 -14 110 42 0 55 -83 81
-114 36z"/>
                <path d="M971 814 c-27 -34 -26 -50 4 -79 27 -28 37 -30 68 -14 43 23 50 66
17 99 -28 28 -64 25 -89 -6z"/>
                <path d="M746 584 c-20 -20 -20 -68 0 -88 36 -37 104 -7 104 46 0 49 -69 78
-104 42z"/>
                <path d="M1206 548 c-10 -38 -7 -48 14 -48 38 0 85 -27 152 -88 87 -79 133
-102 217 -109 63 -5 68 -8 80 -35 28 -63 114 -91 185 -60 113 51 117 203 6
264 -66 36 -160 6 -195 -63 -14 -26 -20 -29 -66 -29 -70 0 -111 20 -188 94
-69 68 -120 96 -170 96 -21 0 -30 -6 -35 -22z m643 -164 c12 -15 21 -34 21
-44 0 -34 -39 -70 -75 -70 -36 0 -75 36 -75 70 0 34 39 70 75 70 24 0 39 -7
54 -26z"/>
            </g>
        </svg>

    )
}

export default DataIntelligence;