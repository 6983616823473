import React from 'react'

const ML = () => {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg" className="h-12 w-12"
            width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
            preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                fill="#000000" stroke="none">
                <path d="M1886 4935 c-158 -40 -309 -163 -384 -313 -23 -46 -44 -98 -48 -117
l-6 -35 -69 0 c-136 0 -252 -42 -348 -127 -100 -87 -171 -231 -171 -344 0 -45
-2 -49 -28 -55 -173 -38 -300 -130 -370 -270 -42 -83 -53 -133 -53 -233 0 -88
23 -186 56 -238 14 -20 12 -23 -38 -51 -69 -39 -122 -99 -160 -181 -27 -57
-31 -77 -32 -151 0 -90 16 -150 60 -220 l22 -36 -18 -69 c-44 -172 -4 -343
112 -469 l50 -55 -26 -77 c-30 -86 -36 -215 -15 -295 33 -124 139 -259 250
-318 64 -34 156 -61 210 -61 l36 0 0 -87 c1 -153 52 -273 160 -376 123 -118
310 -165 476 -118 37 10 70 16 73 13 3 -3 5 -21 5 -41 0 -46 38 -153 74 -210
62 -97 175 -180 295 -215 111 -33 269 -15 375 42 73 39 168 136 205 209 64
129 61 -1 61 2094 l0 1907 -23 80 c-54 184 -199 339 -380 402 -81 29 -264 37
-351 15z m285 -111 c88 -26 150 -63 215 -128 112 -113 144 -204 144 -417 l0
-148 -37 17 c-28 12 -67 16 -143 17 -98 0 -110 -2 -173 -32 -209 -99 -307
-342 -221 -549 38 -92 85 -124 126 -87 25 22 23 35 -14 110 -60 123 -36 265
62 363 63 63 118 85 220 85 67 0 95 -5 133 -22 l47 -22 -2 -958 -3 -958 -25
-49 c-32 -62 -107 -130 -167 -150 -26 -9 -78 -16 -117 -16 -138 0 -252 84
-296 216 -23 67 -44 86 -82 76 -47 -12 -46 -68 1 -168 37 -79 114 -154 198
-196 65 -32 73 -33 183 -33 103 0 121 3 171 26 31 15 75 44 98 64 l42 38 -3
-659 -3 -659 -23 -58 c-33 -81 -116 -168 -200 -209 -63 -31 -73 -33 -172 -33
-101 0 -109 2 -173 34 -117 60 -196 167 -217 298 -13 73 -6 105 35 162 35 49
129 232 150 292 14 39 7 62 -25 79 -36 19 -58 -7 -130 -154 -83 -171 -123
-214 -228 -246 -152 -47 -313 -4 -417 112 -70 77 -113 206 -100 303 l6 53 50
6 c27 4 69 15 94 26 51 22 149 109 172 153 40 79 -55 124 -100 48 -55 -93
-227 -148 -377 -121 -235 42 -381 231 -350 450 9 62 28 120 39 120 4 0 35 -11
69 -25 112 -45 271 -55 307 -18 43 42 -5 100 -72 89 -55 -9 -146 9 -217 42
-235 111 -317 402 -176 622 59 92 168 168 265 185 76 14 101 46 75 96 -20 36
-130 16 -242 -43 -54 -29 -178 -139 -178 -159 0 -5 -4 -9 -10 -9 -12 0 -40 94
-40 135 0 87 54 186 127 234 66 44 94 47 155 18 29 -14 105 -37 168 -51 103
-23 117 -24 137 -10 25 16 30 51 11 77 -8 9 -50 24 -103 36 -225 49 -293 104
-314 255 -19 133 21 259 111 349 50 50 145 101 210 112 l36 7 32 -66 c36 -76
102 -146 182 -194 89 -53 113 -59 138 -37 38 34 23 63 -60 122 -87 60 -131
108 -168 178 -23 43 -27 62 -27 140 0 79 4 97 28 148 76 154 253 248 418 223
43 -6 45 -8 89 -94 34 -67 64 -107 130 -174 91 -92 116 -104 152 -71 32 28 22
58 -33 105 -70 59 -143 159 -179 245 l-30 71 20 64 c45 148 156 260 310 315
80 28 209 31 291 7z"/>
                <path d="M1562 3748 c-20 -20 -14 -57 18 -104 43 -62 61 -119 61 -194 1 -132
-68 -243 -186 -301 -58 -29 -70 -31 -142 -27 -59 3 -94 11 -142 33 -65 29 -82
28 -104 -7 -22 -34 0 -64 75 -99 69 -32 79 -34 183 -34 103 0 114 2 175 32 41
20 88 55 125 92 52 53 65 61 114 69 196 34 371 -111 371 -308 0 -73 -28 -153
-73 -206 -19 -21 -36 -47 -40 -56 -7 -20 28 -68 49 -68 30 0 101 80 135 152
32 69 34 79 34 178 0 97 -2 110 -32 173 -42 88 -116 166 -197 206 -63 31 -171
53 -217 44 l-24 -4 0 123 c0 117 -2 126 -31 188 -41 87 -78 130 -113 130 -15
0 -32 -5 -39 -12z"/>
                <path d="M1559 2877 c-36 -18 -36 -57 0 -107 120 -166 51 -409 -137 -480 -83
-32 -155 -24 -268 28 -106 49 -188 64 -276 52 -120 -17 -176 -58 -149 -109 16
-29 50 -35 96 -15 25 10 67 17 112 17 61 0 82 -5 137 -32 121 -59 182 -164
174 -301 -5 -82 -26 -135 -78 -194 -36 -40 -39 -79 -8 -100 31 -22 33 -21 82
24 98 91 144 281 99 410 -11 30 -23 62 -27 71 -6 13 2 17 49 22 69 7 169 52
223 102 167 150 189 423 48 583 -31 36 -49 42 -77 29z"/>
                <path d="M1765 1596 c-113 -28 -237 -118 -237 -170 0 -31 24 -56 54 -56 10 0
37 17 61 39 69 64 121 85 207 85 61 1 86 -4 131 -25 150 -71 223 -211 185
-358 -26 -103 -19 -131 36 -131 37 0 55 29 74 120 61 295 -220 568 -511 496z"/>
                <path d="M3641 4390 c-94 -20 -180 -95 -221 -193 l-24 -57 -303 0 c-290 0
-304 -1 -323 -20 -11 -11 -20 -27 -20 -35 0 -8 9 -24 20 -35 19 -19 33 -20
324 -20 l304 0 17 -50 c22 -66 92 -142 163 -177 117 -57 270 -28 357 69 59 65
80 120 80 213 0 67 -4 89 -28 137 -61 124 -209 196 -346 168z"/>
                <path d="M4473 3492 c-75 -27 -136 -81 -172 -154 -17 -33 -31 -67 -31 -75 0
-11 -75 -13 -414 -13 -393 0 -415 -1 -438 -19 -13 -11 -80 -83 -147 -160
l-123 -140 -185 -3 c-169 -3 -186 -5 -199 -22 -18 -26 -18 -51 2 -70 13 -14
48 -16 223 -16 l207 0 140 160 139 159 397 1 398 0 6 -27 c17 -73 85 -154 164
-195 60 -31 169 -37 239 -13 69 23 151 99 182 167 31 71 32 183 2 249 -66 147
-241 224 -390 171z"/>
                <path d="M2766 2284 c-20 -19 -20 -44 -2 -70 13 -17 30 -19 199 -22 l185 -3
123 -140 c67 -77 134 -149 147 -160 23 -18 45 -19 438 -19 339 0 414 -2 414
-13 0 -8 14 -42 31 -76 116 -233 454 -222 562 18 30 66 29 178 -2 249 -30 66
-113 144 -177 166 -60 20 -157 21 -217 0 -81 -28 -172 -126 -191 -206 l-6 -28
-398 0 -397 1 -139 159 -140 160 -207 0 c-175 0 -210 -2 -223 -16z"/>
                <path d="M3630 1337 c-96 -27 -186 -110 -215 -198 l-17 -49 -307 0 c-286 0
-309 -1 -324 -18 -22 -24 -21 -55 1 -75 16 -15 54 -17 323 -17 l305 0 28 -65
c80 -184 297 -248 462 -134 154 106 177 326 49 467 -71 79 -204 118 -305 89z"/>
            </g>
        </svg>

    );
}

export default ML;