import React from "react";

const FacatacyFullNavIcon = () => {
  return (
    <svg
      className="sm:h-14 lg:h-20 "
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 579.04 183.32"
      style={{ enableBackground: "new 0 0 579.04 183.32" }}
      xmlSpace="preserve"
    >
      <style>
        {`
                        .st0 { fill: #FFFFFF; }
                        .st1 { fill: #2475BB; }
                    `}
      </style>
      <g>
        <path
          className="st1"
          d="M317.37,66.88v12.25h-21.03v37.33h-14.02V79.12H261.2V66.88H317.37z"
        />
        <path
          className="st1"
          d="M480.2,66.88h16.08l-22.73,30.59v18.98h-14.02V97.47l-22.73-30.59h16.15l13.6,19.83L480.2,66.88z"
        />
        <rect x="82.77" y="66.88" className="st1" width="58.2" height="14.02" />
        <rect
          x="82.77"
          y="94.93"
          className="st1"
          width="29.92"
          height="14.02"
        />
        <polygon
          className="st1"
          points="199.96,116.46 185.01,116.46 166.51,81.19 147.98,116.46 133.05,116.46 133.13,116.3 159.12,66.89 
                        173.92,66.89 	"
        />
        <polygon
          className="st1"
          points="377.53,116.46 362.57,116.46 344.07,81.19 325.54,116.46 310.62,116.46 310.7,116.3 336.69,66.89 
                        351.48,66.89 	"
        />
        <g>
          <path
            className="st1"
            d="M232.07,105.64c-10.75,0-19.81-6.4-19.81-13.98c0-7.59,9.06-13.99,19.81-13.99c6.16,0,11.79,2.13,15.46,5.37
                            h0.02l8.66-8.68c-5.89-5.54-14.53-9.01-24.14-9.01c-17.72,0-32.11,11.79-32.11,26.3c0,14.49,14.4,26.28,32.11,26.28
                            c9.61,0,18.25-3.47,24.14-8.99l-8.68-8.68C243.87,103.53,238.24,105.64,232.07,105.64z"
          />
        </g>
        <g>
          <path
            className="st1"
            d="M409.64,105.64c-10.75,0-19.81-6.4-19.81-13.98c0-7.59,9.06-13.99,19.81-13.99c6.16,0,11.79,2.13,15.46,5.37
                            h0.02l8.66-8.68c-5.89-5.54-14.53-9.01-24.14-9.01c-17.72,0-32.11,11.79-32.11,26.3c0,14.49,14.4,26.28,32.11,26.28
                            c9.61,0,18.25-3.47,24.14-8.99l-8.68-8.68C421.43,103.53,415.8,105.64,409.64,105.64z"
          />
        </g>
      </g>
    </svg>
  );
};
export default FacatacyFullNavIcon;