import React, { useState } from "react";
import apnNewsImg from "../../assets/NewsIcon/apnNewsImg.jpeg";
import aniNewsImg from "../../assets/NewsIcon/ani-news.jpg";
import kalkinemediaNewsImg from "../../assets/NewsIcon/kalkinemedia.jpeg";
import techTimesIndiaNewsImg from "../../assets/NewsIcon/tech-times-india.jpeg";
import printNewsInstead from "../../assets/NewsIcon/factacy_cover.jpg";
import useMediaQuery from "../../hooks/useMediaQuery";
import { motion } from "framer-motion";
import Slider from "react-slick";

const WeInNews = () => {
  const news = [
    {
      title:
        "Factacy Launches Aicite: Delivering Business News Summaries with Gen AI",
      newsUrl:
        "https://www.aninews.in/news/business/business/factacy-launches-aicite-delivering-business-news-summaries-with-gen-ai20240124102220/",
      imgUrl: aniNewsImg,
      summary:
        "News summary platforms provide huge value to users. Aicite represents a significant shift in reshaping how business news is consumed. Aicite users can enjoy in-depth insights on the latest in the business world, thanks to Aicite's innovative use of Gen AI and data algorithms.",
      agencyUrl:
        "https://d3lzcn6mbbadaf.cloudfront.net/static/img/logo-black.png",
    },
    {
      title:
        "BluePine Foods partners with Factacy & Proxgy to revolutionise food-making with AI and IoT",
      newsUrl:
        "https://theprint.in/ani-press-releases/bluepine-foods-partners-with-factacy-proxgy-to-revolutionise-food-making-with-ai-and-iot/1807526/",
      imgUrl: printNewsInstead,
      summary:
        "BluePine Foods Private Limited (Brand YANGKIEZ by MOMO MAMI), a well-known food company, has announced a strategic collaboration with Factacy, a leading AI solutions provider, and Proxgy, an innovative IoT solution provider, in its quest to revolutionise food-making through cutting-edge AI and IoT technologies.",
      agencyUrl:
        "https://static.theprint.in/wp-content/uploads/2022/01/logo_800x149_transp-11.png",
    },
    {
      title:
        "Factacy and Chitkara University Join Hands to Accelerate AI Revolution in Indian Startup Ecosystem",
      newsUrl:
        "https://apnews.com/press-release/ein-presswire-newsmatics/factacy-and-chitkara-university-join-hands-to-accelerate-ai-revolution-in-indian-startup-ecosystem-53ac44e24b1f507066975370f787c74e",
      imgUrl: apnNewsImg,
      summary:
        'Factacy partners with Chitkara University to fuel the AI revolution in Indian startups, merging academia and industry expertise for transformative growth. "We are thrilled to partner with Chitkara University. Together, we will create a robust ecosystem that empowers startups with AI tech of tomorrow and propels India’s startup landscape to new heights.”— Inderjit Singh Makkar, Founder and CEO of Factacy Private Limited.',
      agencyUrl:
        "https://passk12.org/wp-content/uploads/2022/06/ap-news-logo.png",
    },
    {
      title:
        "THIS INDIAN AI STARTUP IS GOING BEYOND DELIVERING MARKET INTELLIGENCE, IT IS HELPING OTHER INDIAN STARTUPS ADOPT AI",
      newsUrl:
        "https://www.techtimesindia.com/article/652567968-this-indian-ai-startup-is-going-beyond-delivering-market-intelligence-it-is-helping-other-indian-startups-adopt-ai",
      imgUrl: techTimesIndiaNewsImg,
      summary:
        "The tidal wave of Artificial Intelligence is rearranging the Indian business scene. In the tech hub of Gurgaon, a young AI startup, Factacy, is making a name for itself, shaking things up for businesses in different fields with its latest AI solutions. This startup is using AI not only to generate market insights for its clients but also to change how Indian Startups work, make important decisions, and grow",
      agencyUrl:
        "https://d2c0db5b8fb27c1c9887-9b32efc83a6b298bb22e7a1df0837426.ssl.cf2.rackcdn.com/4526379-tech-times-india-logo-400x91.svg",
    },
    {
      title:
        "Bits and Bites: Three Indian Startups work together to deliver Himalayan Delights with an AI Twist",
      newsUrl:
        "https://kalkinemedia.com/news/world-news/bits-and-bites-three-indian-startups-work-together-to-deliver-himalayan-delights-with-an-ai-twist",
      imgUrl: kalkinemediaNewsImg,
      summary:
        "Food item and recipe monitoring done through Artificial Intelligence by Factacy Ai Factacy, Ai as A Service Company helping brands adopt Ai and increase ROI Food item and recipe monitoring done throug... ",
      agencyUrl:
        "https://kalkinemedia.com/resources/assets/public/img/kalkine_media_logo_svg.svg",
    },
  ];
  const [active, setActive] = useState(0);
  const settings = {
    infinite: true,
    slidesToShow: 2,
    // slidesToScroll: 1,
    autoplay: true,
    speed: 2500,
    autoplaySpeed: 2500,
    cssEase: "linear",
    pauseOnHover: true,
  };
  const isDesktop = useMediaQuery("(min-width: 768px)");

  return (
    <section className="w-full mt-12 mb-20 sm:px-4 lg:bg-gradient-to-b from-white via-[#E8F5FF] to-white">
      <div>
        <div className="flex flex-col items-center justify-center">
          <div className="lg:w-2/6 sm:w-full">
            <p
              className=" font-bold lg:text-[2.5rem] sm:text-3xl text-center lg:leading-[50px] sm:leading-none relative z-20"
              style={{ letterSpacing: "-0.02em" }}
            >
              Factacy in the news
            </p>
          </div>
          <motion.svg
            className="lg:w-[345px] sm:w-[260px] -mt-1 lg:-mt-2 z-10"
            height="19"
            viewBox="0 0 478 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <motion.path
              initial={{ pathLength: 0 }}
              animate={{ pathLength: 1 }}
              transition={{
                duration: 1,
                ease: "easeInOut",
                repeat: Infinity,
                repeatType: "loop",
                repeatDelay: 2,
              }}
              d="M3.94805 15.7609C84.6154 -0.425883 274.277 0.149277 474.307 15.8478"
              stroke="#3E91EE"
              strokeWidth="6"
              strokeLinecap="round"
            />
          </motion.svg>
        </div>
        {/*  */}
        <div
          className={`mt-10 ${
            isDesktop ? "flex gap-6 px-24 w-[60rem] mx-auto" : "w-full"
          } `}
        >
          {isDesktop ? (
            <div className={`flex flex-col gap-3 w-1/6 py-6`}>
              {news.map((item, index) => (
                <div
                  key={index}
                  className={`flex flex-1 bg-white items-center w-full justify-center rounded-md px-4 outline outline-blue hover:outline-[0.15rem] ${
                    index === active
                      ? "outline-[0.18rem] "
                      : " outline-[0.04rem] "
                  }`}
                >
                  <img
                    src={item.agencyUrl}
                    className="cursor-pointer w-full h-full object-contain"
                    onClick={() => setActive(index)}
                  />
                </div>
              ))}
            </div>
          ) : (
            <Slider {...settings}>
              {news.map((item, index) => (
                <div
                  key={index}
                  className="flex flex-1 items-center w-40 h-20 justify-center px-4"
                >
                  <img
                    src={item.agencyUrl}
                    className="cursor-pointer w-full h-full object-contain"
                    onClick={() => setActive(index)}
                  />
                </div>
              ))}
            </Slider>
          )}

          <div className="p-4 rounded-md bg-white border shadow-md flex flex-col gap-3 flex-1 lg:w-full">
            <a
              href={news[active].newsUrl}
              className={`w-full rounded-md border aspect-video bg-no-repeat bg-contain bg-center`}
              style={{ backgroundImage: `url(${news[active].imgUrl})` }}
              target="_blank"
            ></a>
            <h1 className="text-md font-medium">{news[active].title}</h1>
            <p className="text-sm">{news[active].summary}</p>
          </div>
        </div>
      </div>
    </section>
  );
};
export default WeInNews;

// import React, { useState } from "react";
// import apnNewsImg from "../../assets/apnNewsImg.webp";
// import printNewsInstead from "../../assets/logistics1.png";

// const WeInNews = () => {
//   const news = [
//     {
//       title:
//         "Factacy Launches Aicite: Delivering Business News Summaries with Gen AI",
//       newsUrl:
//         "https://www.aninews.in/news/business/business/factacy-launches-aicite-delivering-business-news-summaries-with-gen-ai20240124102220/",
//       imgUrl:
//         "https://d3lzcn6mbbadaf.cloudfront.net/media/details/ANI-20240124045206.jpg",
//       summary:
//         "News summary platforms provide huge value to users. Aicite represents a significant shift in reshaping how business news is consumed. Aicite users can enjoy in-depth insights on the latest in the business world, thanks to Aicite's innovative use of Gen AI and data algorithms.",
//       agencyUrl:
//         "https://d3lzcn6mbbadaf.cloudfront.net/static/img/logo-black.png",
//     },
//     {
//       title:
//         "BluePine Foods partners with Factacy & Proxgy to revolutionise food-making with AI and IoT",
//       newsUrl:
//         "https://theprint.in/ani-press-releases/bluepine-foods-partners-with-factacy-proxgy-to-revolutionise-food-making-with-ai-and-iot/1807526/",
//       imgUrl: printNewsInstead,
//       summary:
//         "BluePine Foods Private Limited (Brand YANGKIEZ by MOMO MAMI), a well-known food company, has announced a strategic collaboration with Factacy, a leading AI solutions provider, and Proxgy, an innovative IoT solution provider, in its quest to revolutionise food-making through cutting-edge AI and IoT technologies.",
//       agencyUrl:
//         "https://static.theprint.in/wp-content/uploads/2022/01/logo_800x149_transp-11.png",
//     },
//     {
//       title:
//         "Factacy and Chitkara University Join Hands to Accelerate AI Revolution in Indian Startup Ecosystem",
//       newsUrl:
//         "https://apnews.com/press-release/ein-presswire-newsmatics/factacy-and-chitkara-university-join-hands-to-accelerate-ai-revolution-in-indian-startup-ecosystem-53ac44e24b1f507066975370f787c74e",
//       imgUrl: apnNewsImg,
//       summary:
//         'Factacy partners with Chitkara University to fuel the AI revolution in Indian startups, merging academia and industry expertise for transformative growth. "We are thrilled to partner with Chitkara University. Together, we will create a robust ecosystem that empowers startups with AI tech of tomorrow and propels India’s startup landscape to new heights.”— Inderjit Singh Makkar, Founder and CEO of Factacy Private Limited.',
//       agencyUrl:
//         "https://passk12.org/wp-content/uploads/2022/06/ap-news-logo.png",
//     },
//     {
//       title:
//         "THIS INDIAN AI STARTUP IS GOING BEYOND DELIVERING MARKET INTELLIGENCE, IT IS HELPING OTHER INDIAN STARTUPS ADOPT AI",
//       newsUrl:
//         "https://www.techtimesindia.com/article/652567968-this-indian-ai-startup-is-going-beyond-delivering-market-intelligence-it-is-helping-other-indian-startups-adopt-ai",
//       imgUrl:
//         "https://img.einpresswire.com/medium/527403/factacy-ai-delivering-financial.jpeg",
//       summary:
//         "The tidal wave of Artificial Intelligence is rearranging the Indian business scene. In the tech hub of Gurgaon, a young AI startup, Factacy, is making a name for itself, shaking things up for businesses in different fields with its latest AI solutions. This startup is using AI not only to generate market insights for its clients but also to change how Indian Startups work, make important decisions, and grow",
//       agencyUrl:
//         "https://d2c0db5b8fb27c1c9887-9b32efc83a6b298bb22e7a1df0837426.ssl.cf2.rackcdn.com/4526379-tech-times-india-logo-400x91.svg",
//     },
//     {
//       title:
//         "Bits and Bites: Three Indian Startups work together to deliver Himalayan Delights with an AI Twist",
//       newsUrl:
//         "https://kalkinemedia.com/news/world-news/bits-and-bites-three-indian-startups-work-together-to-deliver-himalayan-delights-with-an-ai-twist",
//       imgUrl:
//         "https://kalkinemedia.com/storage/uploads/large/1690977614_64ca454e2fab1_17879945_factacy_ai_food_recognition_usi_2250x4000.jpeg",
//       summary:
//         "Food item and recipe monitoring done through Artificial Intelligence by Factacy Ai Factacy, Ai as A Service Company helping brands adopt Ai and increase ROI Food item and recipe monitoring done throug... ",
//       agencyUrl:
//         "https://kalkinemedia.com/resources/assets/public/img/kalkine_media_logo_svg.svg",
//     },
//   ];
//   const [active, setActive] = useState(0);

//   return (
//     <div className="flex flex-col gap-4 px-4">
//       <div className="flex gap-6 justify-between items-center w-max">
//         {news.map((item, index) => (
//           <div
//             key={index}
//             className="flex flex-1 items-center w-40 h-20 justify-center"
//           >
//             <img
//               src={item.agencyUrl}
//               className="cursor-pointer w-full h-full object-contain"
//               onClick={() => setActive(index)}
//             />
//           </div>
//         ))}
//       </div>
//       <div className="p-4 rounded-md bg-white border shadow-md flex flex-col gap-1 flex-1">
//         <a
//           href={news[active].newsUrl}
//           className="h-40 w-full border bg-no-repeat bg-contain bg-center"
//           style={{ backgroundImage: `url(${news[active].imgUrl})` }}
//           target="_blank"
//         ></a>
//         <h1 className="text-md font-medium">{news[active].title}</h1>
//         <p className="text-sm">{news[active].summary}</p>
//       </div>
//     </div>
//   );
// };

// export default WeInNews;
