import React from 'react'

const ComputerVision = () => {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            width="200.000000pt" height="200.000000pt" viewBox="0 0 200.000000 200.000000"
            preserveAspectRatio="xMidYMid meet" className="h-12 w-12">

            <g transform="translate(0.000000,200.000000) scale(0.100000,-0.100000)"
                fill="#000000" stroke="none">
                <path d="M329 1705 c-55 -30 -61 -105 -14 -149 25 -23 25 -24 25 -216 l0 -192
-60 -65 c-33 -36 -60 -74 -60 -83 0 -9 27 -47 60 -83 l60 -65 0 -192 c0 -192
0 -193 -25 -216 -50 -46 -40 -122 21 -151 39 -19 77 -11 108 22 l23 25 533 0
533 0 23 -25 c31 -33 69 -41 108 -22 61 29 71 105 21 151 -25 23 -25 24 -25
216 l0 192 60 65 c33 36 60 74 60 83 0 9 -27 47 -60 83 l-60 65 0 192 c0 192
0 193 25 216 33 31 41 69 22 108 -29 61 -105 71 -151 21 l-23 -25 -537 0
c-474 0 -537 2 -543 15 -7 19 -55 45 -80 45 -10 -1 -30 -7 -44 -15z m75 -70
c7 -19 -20 -46 -39 -39 -21 8 -26 32 -9 48 16 17 40 12 48 -9z m1250 0 c3 -8
-2 -21 -10 -29 -8 -8 -21 -13 -29 -10 -21 8 -26 32 -9 48 16 17 40 12 48 -9z
m-93 -74 l29 -29 0 -162 0 -162 -35 30 c-96 85 -267 177 -385 209 -42 11 -102
17 -170 17 -68 0 -128 -6 -170 -17 -118 -32 -289 -124 -385 -209 l-35 -30 0
162 0 161 28 29 27 29 533 1 534 0 29 -29z m-406 -178 c142 -34 337 -158 485
-307 l74 -76 -74 -76 c-149 -150 -340 -271 -490 -310 -36 -9 -103 -16 -150
-16 -47 0 -114 7 -150 16 -150 39 -341 160 -490 310 l-74 76 74 76 c141 143
320 260 455 300 120 34 219 37 340 7z m-572 -721 c132 -80 298 -132 418 -132
159 0 371 85 530 212 l59 48 0 -161 0 -161 -29 -29 -29 -29 -532 0 -532 0 -29
29 -29 29 0 161 0 161 59 -48 c33 -26 84 -62 114 -80z m-179 -277 c3 -8 -2
-21 -10 -29 -8 -8 -21 -13 -29 -10 -21 8 -26 32 -9 48 16 17 40 12 48 -9z
m1250 0 c7 -19 -20 -46 -39 -39 -21 8 -26 32 -9 48 16 17 40 12 48 -9z"/>
                <path d="M917 1334 c-4 -4 -7 -30 -7 -57 l0 -49 -40 36 c-22 20 -44 36 -48 36
-4 0 -34 -26 -66 -59 -64 -64 -64 -71 -11 -121 l27 -25 -54 -5 -53 -5 0 -85 0
-85 53 -3 53 -3 -35 -40 c-20 -21 -36 -43 -36 -48 0 -4 27 -34 60 -65 65 -62
72 -63 120 -11 l25 27 5 -54 5 -53 85 0 85 0 5 53 5 54 25 -27 c50 -53 57 -53
121 11 33 32 59 62 59 66 0 4 -16 26 -36 47 l-35 40 53 3 53 3 0 85 0 85 -53
3 -53 3 35 40 c20 21 36 43 36 47 0 4 -26 34 -59 66 -64 64 -71 64 -121 11
l-25 -27 -5 54 -5 53 -80 3 c-45 1 -84 0 -88 -4z m115 -100 c3 -43 5 -47 41
-61 38 -16 39 -15 71 16 l32 31 22 -22 22 -22 -32 -33 c-29 -30 -30 -33 -18
-65 17 -42 26 -48 73 -48 34 0 37 -2 37 -29 0 -28 -2 -30 -46 -33 -43 -3 -47
-5 -61 -41 -16 -38 -15 -39 16 -71 l31 -32 -22 -22 -22 -22 -32 31 c-32 31
-33 32 -71 16 -36 -14 -38 -18 -41 -61 -3 -44 -5 -46 -33 -46 -27 0 -29 3 -29
38 0 46 -6 55 -48 72 -32 12 -35 11 -65 -18 l-33 -32 -22 22 -22 22 31 32 c31
32 32 33 16 71 -14 36 -18 38 -61 41 -44 3 -46 5 -46 33 0 27 3 29 38 29 46 0
55 6 72 48 12 32 11 35 -18 65 l-32 33 22 22 22 22 33 -32 c30 -29 33 -30 65
-18 42 17 48 26 48 73 0 34 2 37 29 37 28 0 30 -2 33 -46z"/>
                <path d="M914 1086 c-30 -30 -34 -40 -34 -86 0 -75 45 -120 120 -120 46 0 56
4 86 34 30 30 34 40 34 86 0 46 -4 56 -34 86 -30 30 -40 34 -86 34 -46 0 -56
-4 -86 -34z m128 -43 c38 -34 11 -103 -40 -103 -54 0 -81 62 -45 102 20 22 61
23 85 1z"/>
            </g>
        </svg>
    );
}

export default ComputerVision;