import React, { useEffect, useRef } from "react";

const AnimatedCard = () => {
  const h2Classes = "text-2xl font-medium font-roboto non-italic leading-9 lg:w-[50%]";
  const pClasses =
    "text-base font-normal font-roboto text-current text-gray-500 lg:w-[50%]";

  const ref1 = useRef();
  const ref2 = useRef();
  const ref3 = useRef();
  const ref4 = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(
      (item, obs) => {
        if (window.screen.width < 768) {
          if (item[0].isIntersecting) {
            item[0].target.children[0].style.transform = "translateX(0)";
          } else {
            item[0].target.children[0].style.transform = "translateX(100%)";
          }
        } else {
          if (item[0].isIntersecting) {
            item[0].target.children[0].style.transform = "translateX(0)";
          } else {
            item[0].target.children[0].style.transform = "translateX(220%)";
          }
        }
      },
      {
        rootMargin: "-120px",
      }
    );
    observer.observe(ref1.current);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (item, obs) => {
        if (window.screen.width < 768) {
          if (item[0].isIntersecting) {
            item[0].target.children[0].style.transform = "translateX(0)";
          } else {
            item[0].target.children[0].style.transform = "translateX(100%)";
          }
        } else {
          if (item[0].isIntersecting) {
            item[0].target.children[0].style.transform = "translateX(50%)";
          } else {
            item[0].target.children[0].style.transform = "translateX(220%)";
          }
        }
      },
      {
        rootMargin: "-120px",
      }
    );
    observer.observe(ref2.current);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (item, obs) => {
        if (window.screen.width < 768) {
          if (item[0].isIntersecting) {
            item[0].target.children[0].style.transform = "translateX(0)";
          } else {
            item[0].target.children[0].style.transform = "translateX(100%)";
          }
        } else {
          if (item[0].isIntersecting) {
            item[0].target.children[0].style.transform = "translateX(100%)";
          } else {
            item[0].target.children[0].style.transform = "translateX(220%)";
          }
        }
      },
      {
        rootMargin: "-120px",
      }
    );
    observer.observe(ref3.current);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (item, obs) => {
        if (window.screen.width < 768) {
          if (item[0].isIntersecting) {
            item[0].target.children[0].style.transform = "translateX(0)";
          } else {
            item[0].target.children[0].style.transform = "translateX(100%)";
          }
        } else {
          if (item[0].isIntersecting) {
            item[0].target.children[0].style.transform = "translateX(150%)";
          } else {
            item[0].target.children[0].style.transform = "translateX(220%)";
          }
        }
      },
      {
        rootMargin: "-120px",
      }
    );
    observer.observe(ref4.current);
  }, []);
  return (
    <section className="mt-12 mb-40">
      <div
        ref={ref1}
        className="m-4 rounded-md w-full lg:mx-5 lg:-mb-[5%] lg:w-1/2 "
      >
        <div
          className=" p-8 duration-500 bg-blue flex flex-col rounded-2xl lg:translate-x-[220%]"
          // style={{ transform: "translateX(220%)" }}
        >
          <svg
            width="64"
            height="64"
            viewBox="0 0 64 64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M37.6565 27.9456C36.6151 26.9042 34.9266 26.9042 33.8853 27.9456L31.9997 29.8314C29.9167 31.9141 26.5399 31.9141 24.4571 29.8314C22.3743 27.7485 22.3743 24.3716 24.4571 22.2888L39.4722 7.26977C45.0826 6.00056 51.2018 7.54995 55.5698 11.918C62.0215 18.3696 62.3239 28.6418 56.4775 35.4517L50.8557 41.145L37.6565 27.9456ZM8.42936 11.918C14.2251 6.12222 23.104 5.28883 29.7863 9.41771L20.6859 18.5176C16.5203 22.6832 16.5203 29.437 20.6859 33.6026C24.7253 37.6418 31.1983 37.7642 35.385 33.9698L35.7709 33.6026L47.0845 44.9162L35.7709 56.2299C33.6879 58.3128 30.3111 58.3128 28.2285 56.2299L8.42936 36.4309C1.66027 29.6618 1.66027 18.687 8.42936 11.918Z"
              fill="white"
            />
            <path
              d="M8.42935 11.918C14.2251 6.12228 23.104 5.28889 29.7863 9.41777L20.6859 18.5177C16.5203 22.6833 16.5203 29.4371 20.6859 33.6027C24.7253 37.6419 31.1983 37.7643 35.385 33.9699L35.7709 33.6027L47.0845 44.9163L35.7709 56.2299C33.6879 58.3128 30.3111 58.3128 28.2285 56.2299L8.42935 36.431C1.66027 29.6619 1.66027 18.6871 8.42935 11.918Z"
              fill="#3E91EE"
            />
          </svg>
          <h2 className={h2Classes}>A legacy of Excellence</h2>
          <p className={pClasses}>
            Profit from Eight Decades of Expertise for Effective Results
          </p>
        </div>
      </div>


      <div
        ref={ref2}
        className="m-4 rounded-md w-full lg:mx-5 lg:-mb-[5%] lg:w-1/2 lg:min-h-[12rem]"
      >
        <div
          className=" p-8 duration-500 bg-green flex flex-col rounded-2xl lg:translate-x-[220%]"
          // style={{ transform: "translateX(220%)" }}
        >
          <svg
            width="64"
            height="64"
            viewBox="0 0 64 64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16.7497 47.5668H47.2497V17.0668H16.7497V47.5668ZM37.083 52.6501H26.9163V57.7334H21.833V52.6501H14.208C12.8043 52.6501 11.6663 51.5122 11.6663 50.1084V42.4834H6.58301V37.4001H11.6663V27.2334H6.58301V22.1501H11.6663V14.5251C11.6663 13.1214 12.8043 11.9834 14.208 11.9834H21.833V6.90009H26.9163V11.9834H37.083V6.90009H42.1663V11.9834H49.7913C51.1951 11.9834 52.333 13.1214 52.333 14.5251V22.1501H57.4163V27.2334H52.333V37.4001H57.4163V42.4834H52.333V50.1084C52.333 51.5122 51.1951 52.6501 49.7913 52.6501H42.1663V57.7334H37.083V52.6501ZM21.833 22.1501H42.1663V42.4834H21.833V22.1501Z"
              fill="white"
            />
            <path
              d="M16.7497 47.5667H47.2497V17.0668H16.7497V47.5667ZM37.083 52.6501H26.9163V57.7334H21.833V52.6501H14.208C12.8043 52.6501 11.6663 51.5122 11.6663 50.1084V42.4834H6.58301V37.4001H11.6663V27.2334H6.58301V22.1501H11.6663V14.5251C11.6663 13.1214 12.8043 11.9834 14.208 11.9834H21.833V6.90009H26.9163V11.9834H37.083V6.90009H42.1663V11.9834H49.7913C51.1951 11.9834 52.333 13.1214 52.333 14.5251V22.1501H57.4163V27.2334H52.333V37.4001H57.4163V42.4834H52.333V50.1084C52.333 51.5122 51.1951 52.6501 49.7913 52.6501H42.1663V57.7334H37.083V52.6501Z"
              fill="#649771"
            />
          </svg>
          <h2 className={h2Classes}>Propelled by AI</h2>
          <p className={pClasses}>
            Infusing Your Business with Generative AI and next gen technologies
          </p>
        </div>
      </div>
      <div
        ref={ref3}
        className="m-4 rounded-md w-full lg:mx-5 lg:-mb-[5%] lg:w-1/2"
      >
        <div
          className=" p-8 duration-500 bg-red flex flex-col rounded-2xl lg:translate-x-[220%]"
          // style={{ transform: "translateX(220%)" }}
        >
          <svg
            width="64"
            height="64"
            viewBox="0 0 64 64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.2108 8.72419C17.4991 7.55518 21.3118 8.28662 23.9437 10.9185C26.5764 13.5512 27.3075 17.3654 26.1371 20.6542L55.0563 49.5738L49.3995 55.2306L20.4773 26.3122C17.1891 27.4813 13.3763 26.7498 10.7444 24.1179C8.11174 21.4852 7.38063 17.671 8.55105 14.3822L14.5157 20.3466C16.0777 21.9087 18.6104 21.9087 20.1725 20.3466C21.7346 18.7845 21.7346 16.2519 20.1725 14.6898L14.2108 8.72419ZM41.8571 13.747L50.3425 9.03294L54.1137 12.8042L49.3995 21.2894L44.6857 22.2322L39.0286 27.889L35.2574 24.1179L40.9142 18.461L41.8571 13.747ZM23.0009 34.4888L28.6577 40.1456L15.4585 53.345C13.8964 54.9072 11.3637 54.9072 9.80161 53.345C8.3314 51.8746 8.24492 49.5448 9.54217 47.9733L9.80161 47.688L23.0009 34.4888Z"
              fill="white"
            />
            <path
              d="M14.2108 8.72419C17.4991 7.55518 21.3118 8.28662 23.9437 10.9185C26.5764 13.5512 27.3075 17.3654 26.1371 20.6542L55.0563 49.5738L49.3995 55.2306L20.4773 26.3122C17.1891 27.4813 13.3763 26.7498 10.7444 24.1179C8.11174 21.4852 7.38063 17.671 8.55105 14.3822L14.5157 20.3466C16.0777 21.9087 18.6104 21.9087 20.1725 20.3466C21.7346 18.7845 21.7346 16.2519 20.1725 14.6898L14.2108 8.72419Z"
              fill="#FF7F7F"
            />
          </svg>
          <h2 className={h2Classes}>Crafting Customized Solutions</h2>
          <p className={pClasses}>
            Precision-engineered AI Solutions Aligned Exclusively with Your
            Business's Requirements
          </p>
        </div>
      </div>
      <div
        ref={ref4}
        className="m-4 rounded-md w-full lg:mx-5 lg:-mb-[5%] lg:w-1/2"
      >
        <div
          className=" p-8 duration-500 bg-yellow flex flex-col rounded-2xl lg:translate-x-[220%]"
          // style={{ transform: "translateX(220%)" }}
        >
          <svg
            width="64"
            height="64"
            viewBox="0 0 64 64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M31.9997 35.5552L7.40594 51.9509C6.79325 52.3595 5.96541 52.1939 5.55693 51.5811C5.41093 51.3621 5.33301 51.1048 5.33301 50.8416V13.1576C5.33301 12.4212 5.92997 11.8242 6.66634 11.8242C6.92957 11.8242 7.18693 11.9021 7.40594 12.0482L31.9997 28.444V13.1576C31.9997 12.4212 32.5967 11.8242 33.333 11.8242C33.5962 11.8242 33.8535 11.9021 34.0727 12.0482L62.3357 30.8901C62.9482 31.2987 63.1138 32.1264 62.7053 32.7392C62.6077 32.8856 62.4821 33.0112 62.3357 33.1088L34.0727 51.9509C33.4599 52.3595 32.6322 52.1939 32.2237 51.5811C32.0775 51.3621 31.9997 51.1048 31.9997 50.8416V35.5552Z"
              fill="#332D00"
            />
            <mask
              id="mask0_311_2552"
              style={{ maskType: "alpha" }}
              maskUnits="userSpaceOnUse"
              x="5"
              y="11"
              width="58"
              height="42"
            >
              <path
                d="M31.9997 35.5552L7.40594 51.9509C6.79325 52.3595 5.96541 52.1939 5.55693 51.5811C5.41093 51.3621 5.33301 51.1048 5.33301 50.8416V13.1576C5.33301 12.4212 5.92997 11.8242 6.66634 11.8242C6.92957 11.8242 7.18693 11.9021 7.40594 12.0482L31.9997 28.444V13.1576C31.9997 12.4212 32.5967 11.8242 33.333 11.8242C33.5962 11.8242 33.8535 11.9021 34.0727 12.0482L62.3357 30.8901C62.9482 31.2987 63.1138 32.1264 62.7053 32.7392C62.6077 32.8856 62.4821 33.0112 62.3357 33.1088L34.0727 51.9509C33.4599 52.3595 32.6322 52.1939 32.2237 51.5811C32.0775 51.3621 31.9997 51.1048 31.9997 50.8416V35.5552Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask0_311_2552)">
              <rect
                x="32"
                y="11.3562"
                width="31.918"
                height="40.8188"
                fill="#FAE243"
              />
            </g>
          </svg>
          <h2 className={h2Classes}>Warp speed agility</h2>
          <p className={pClasses}>
            Our Innovative solutions are ready when you are
          </p>
        </div>
      </div>
    </section>
  );
};

export default AnimatedCard;