import React, { useState } from "react";
import factacy from "../../assets/factacy_logo.svg";
import kranti from "../../assets/kranti anand.jpeg";
import aditi from "../../assets/aditi madan.jpeg";
import piyush from "../../assets/piyush garg.jpeg";
import useMediaQuery from "../../hooks/useMediaQuery";
import SectionTitle from "../SectionTitle";

const Testimonials = () => {
  const [active, setActive] = useState(0);

  const list = [
    {
      thought:
        " “ARRCOAT believes in building businesses for the future. Our innovative product offering will benefit from an AI ecosystem around it, delivering customer delight. Factacy’s passion and expertise in designing a digital transformation strategy along with building custom AI solutions for us will help us scale and benefit the customers far and wide.”",
      profileSrc: kranti,
      name: "Kranti Anand",
      title: "MD, ARRCOAT",
      lightColor: "#FFFFE6",
      darkColor: "#FFFFB8",
    },
    {
      thought:
        "“We believe that Factacy's tailored AI solutions will complement our efforts to meet the unique requirements of our customers and enhance our food manufacturing processes. Their agile approach to delivering solutions aligns perfectly with our commitment to providing top-notch products and services to our clients.”",
      profileSrc: aditi,
      name: "Aditi Bhutia Madan",
      title: "Master Chef and Director, BluePine Foods Private Limited",
      lightColor: "#f4e8fe",
      darkColor: "#DDB9FF",
    },
    {
      thought:
        "“Deep synergies and active collaboration with new age ventures like Factacy are going to propel India's Innovation ecosystem while giving the realistic catalytic growth to the economy. Together, we aim to create a robust ecosystem that empowers upcoming entrepreneurs and India's startup landscape to new heights.”",
      profileSrc: piyush,
      name: "Piyush Garg",
      title: "Vice President, (CEED) Chitkara University",
      lightColor: "#E4FFEB",
      darkColor: "#B5EEC3",
    },
  ];

  const isDesktop = useMediaQuery("(min-width: 768px)");
  return (
    <section className="w-screen items-end mt-20 mb-20 lg:px-24 xl:px-36 xxl:container">
      <div className="flex flex-col items-center justify-center">
        <SectionTitle text={"TESTIMONIALS"} />
        <div className="lg:w-3/6 sm:w-full">
          <p
            className="font-bold lg:text-[2.5rem] sm:text-3xl text-center lg:leading-[50px] sm:leading-none"
            style={{ letterSpacing: "-0.02em" }}
          >
            Hear from Our <br /> Delighted Customers
          </p>
        </div>
        <svg
          className="lg:w-[403px] sm:w-[303px]"
          height="16"
          viewBox="0 0 453 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.01094 12.805C79.5949 -0.350201 259.65 0.0867622 449.545 12.8011"
            stroke="#3E91EE"
            strokeWidth="6"
            strokeLinecap="round"
          />
        </svg>
      </div>
      {/*  */}

      <div className="flex flex-col gap-4 w-full p-5 lg:flex-row ">
        {list.map((item, index) => (
          <div
            key={index}
            className={`${
              !isDesktop
                ? active === index
                  ? "h-fit"
                  : "h-[5rem]"
                : active === index
                ? "w-fit"
                : "w-[8rem]"
            } flex p-6 rounded-2xl flex-col gap-6 lg:flex-row ${
              active !== index ? "cursor-pointer" : ""
            }`}
            style={{
              backgroundColor: item.lightColor,
              overflow: "hidden",
              transition: " all .6s cubic-bezier(.28,-0.03,0,.99)",
            }}
            onClick={() => setActive(index)}
          >
            <div className="sm:order-last relative sm:w-max lg:order-first lg:w-1/2">
              <p
                className="px-4 py-2 h-fit rounded-full lg:absolute lg:-left-12 sm:left-0 lg:bottom-20 sm:bottom-0 lg:-rotate-90 w-40"
                style={{
                  boxShadow:
                    "0px 0.3px 0.9px rgba(0, 0, 0, 0.12), 0px 1.6px 3.6px rgba(0, 0, 0, 0.16)",
                  backgroundColor: item.darkColor,
                }}
              >
                Factacy.ai Client
              </p>
            </div>
            {active === index && (
              <div className="flex flex-col relative gap-6 sm:px-0 lg:px-6">
                <div className="flex flex-row sm:gap-6 lg:gap-0">
                  <div>
                    <svg
                      width="64"
                      height="65"
                      viewBox="0 0 64 65"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.2224 46.5056C9.47509 43.5893 8 40.316 8 35.0104C8 25.6785 14.5507 17.3126 24.0816 13.178L26.4621 16.8516C17.5681 21.6639 15.8298 27.9056 15.135 31.8416C16.567 31.1011 18.4415 30.8405 20.2792 31.0109C25.0909 31.4568 28.8832 35.4069 28.8832 40.316C28.8832 45.4707 24.7044 49.6493 19.5498 49.6493C16.6883 49.6493 13.9519 48.3416 12.2224 46.5056ZM38.8891 46.5056C36.1419 43.5893 34.6667 40.316 34.6667 35.0104C34.6667 25.6785 41.2173 17.3126 50.7483 13.178L53.1288 16.8516C44.2347 21.6639 42.4965 27.9056 41.8016 31.8416C43.2336 31.1011 45.1083 30.8405 46.9459 31.0109C51.7576 31.4568 55.5499 35.4069 55.5499 40.316C55.5499 45.4707 51.3712 49.6493 46.2165 49.6493C43.3549 49.6493 40.6187 48.3416 38.8891 46.5056Z"
                        fill={item.darkColor}
                      />
                    </svg>
                  </div>
                  <div className="w-11/12">
                    <p
                      className="italic font-normal roboto lg:leading-8 sm:leading-0 lg:text-xl sm:text-base"
                      style={{ color: "#71717A" }}
                    >
                      {item.thought}
                    </p>
                  </div>
                </div>
                <div className="flex flex-row items-center gap-4">
                  <img
                    src={item.profileSrc}
                    className="lg:w-28 sm:w-16 sm:h-16 lg:h-28 rounded-full"
                    alt="customer"
                  />
                  <div>
                    <p className="font-medium text-xl">{item.name}</p>
                    <p
                      className="lg:text-base sm:text-sm"
                      style={{ color: "#71717A" }}
                    >
                      {item.title}
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </section>
  );
};
export default Testimonials;
