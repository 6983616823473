import React from "react";

const CaseStudyProblems = ({ problem, solution}) => {
  return (
    <div className="flex sm:flex-col lg:flex-row mt-12 sm:gap-2 lg:gap-8 problem">
      <div
        className="bg-purple sm:w-full lg:w-1/2 p-5 h-max rounded-xl gap-3 flex flex-col"
      >
        <svg
          width="49"
          height="49"
          viewBox="0 0 49 49"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.4"
            d="M10.2878 42.501C10.2618 42.501 10.2378 42.501 10.2098 42.499C9.5798 42.467 8.9618 42.331 8.3738 42.093C5.4798 40.917 4.0838 37.611 5.2578 34.719L19.8998 9.067C20.4038 8.155 21.1678 7.391 22.0998 6.875C24.8298 5.363 28.2818 6.357 29.7918 9.085L44.3378 34.541C44.6618 35.303 44.7998 35.923 44.8338 36.555C44.9118 38.067 44.3958 39.517 43.3838 40.639C42.3718 41.761 40.9818 42.423 39.4718 42.499L10.4318 42.501H10.2878Z"
            fill="#C68BFF"
          />
          <path
            d="M24.8418 30.9462C25.8058 30.9462 26.5918 31.7262 26.5918 32.6842C26.5918 33.6702 25.8058 34.4562 24.8418 34.4562C23.8778 34.4562 23.0918 33.6702 23.0918 32.7062C23.0918 31.7362 23.8778 30.9462 24.8418 30.9462ZM24.8418 18.458C25.8058 18.458 26.5918 19.244 26.5918 20.208V25.864C26.5918 26.83 25.8058 27.614 24.8418 27.614C23.8778 27.614 23.0918 26.83 23.0918 25.864V20.208C23.0918 19.244 23.8778 18.458 24.8418 18.458Z"
            fill="black"
          />
        </svg>
        <a id="problem" className="font-bold text-xl">
          {problem?.text}
        </a>
        <p className="text-sm text-gray-600">{problem?.description}</p>
      </div>
      <div className="bg-blue sm:w-full lg:w-1/2 p-5 h-max rounded-xl gap-3 flex flex-col relative top-12">
        <svg
          width="49"
          height="49"
          viewBox="0 0 49 49"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M24.9368 44.1665C24.6891 44.1665 24.4415 44.1096 24.218 43.9939L17.0158 40.2657C14.9721 39.2067 13.3734 38.0182 12.1251 36.6336C9.39278 33.6056 7.87261 29.7185 7.84845 25.6863L7.76381 12.415C7.75382 10.8833 8.74243 9.50856 10.2203 8.99081L23.4448 4.38008C24.2301 4.09963 25.1059 4.09571 25.9053 4.36635L39.1801 8.82019C40.666 9.31637 41.6708 10.6813 41.6788 12.2111L41.7634 25.4922C41.7896 29.5185 40.3217 33.4212 37.6317 36.4826C36.3975 37.8868 34.8129 39.0929 32.7893 40.1716L25.6516 43.9841C25.4301 44.1037 25.1845 44.1645 24.9368 44.1665Z"
            fill="#53A4FF"
          />
          <path
            d="M23.4015 28.8084C23.0149 28.8103 22.6283 28.671 22.3303 28.3847L18.4967 24.6977C17.9047 24.125 17.8987 23.1954 18.4846 22.6189C19.0705 22.0403 20.0269 22.0344 20.6209 22.6051L23.3793 25.2566L30.1144 18.6161C30.7023 18.0375 31.6587 18.0317 32.2507 18.6024C32.8447 19.175 32.8507 20.1066 32.2648 20.6812L24.4666 28.371C24.1726 28.6612 23.7881 28.8064 23.4015 28.8084Z"
            fill="black"
          />
        </svg>
        <p className="font-bold text-xl">{solution?.text}</p>
        <p className="text-sm text-gray-600">{solution?.description}</p>
      </div>
    </div>
  );
};

export default CaseStudyProblems;