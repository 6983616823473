import React from 'react'
import Big_Button from '../../Pages/AOT/Big_Button';
import mainImg from '../../assets/AOT/analyze-aot.svg';

const AOT_Section = () => {
    return (
        <div className='sm:px-8 lg:px-32 w-full flex flex-col lg:flex-row lg:items-center lg:gap-16 min-h-screen relative z-20'>
            <div className=' flex-1 flex flex-col gap-6'>
                <h1 className=' text-5xl font-bold'>Analytics of Things</h1>
                <h1 className=' text-3xl'>Analyze IoT data in real-time and drive meaningful change.</h1>
                <h1 className=' text-xl font-roboto'>In a world where devices are interconnected, the data they generate
                    holds the key to smarter decisions and better outcomes</h1>
                <Big_Button text={"Start your AOT journey"} navigate={"/analytics-of-things"} />
            </div>
            <div className=' flex-1'>
                <img src={mainImg} alt="" />
            </div>
        </div>
    )
}

export default AOT_Section;